.sign-in-page {
  .new-account {
    .email-promo {
      .label-content {
        display: inline-block;
        width: 90%;
        vertical-align: top;
        font-size: 13px;
      }
    }
  }
}

.address-book-page {
  &__overlay {
    .address-form {
      .postcode-needhelp,
      .country-field,
      .instructions {
        width: 100%;
        margin-bottom: 10px;
      }
      .instructions {
        margin-top: 15px;
      }
      .ship_type-field {
        @include breakpoint($landscape-up) {
          width: 50%;
        }
        .form-item {
          float: #{$ldirection};
          width: 50%;
        }
      }
      .field {
        font-size: 14px;
      }
    }
  }
}

.account-orders-list {
  .order-details-page__content {
    @include breakpoint($medium-up) {
      float: none;
    }
  }
}

.pre-order-message-display {
  float: $ldirection;
  clear: both;
}

.address-info {
  .address-item {
    &__controls {
      display: flex;
      justify-content: $rdirection;
    }
  }
}

.link {
  text-transform: none;
}

span.link-like {
  cursor: pointer;
  color: $color-link;
  text-decoration: underline;
}

.store-locator-section__list-item {
  &--left {
    width: 35%;
    @include breakpoint($medium-up) {
      width: 10%;
    }
  }
  &--distance {
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &--middle {
    width: 64%;
  }
}

.mpp-sort {
  .selectBox {
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 10px;
      min-width: 240px;
      &-label {
        @include swap-direction(padding, 0 40px 0 15px);
      }
    }
  }
}

.product-full__container {
  .product-full__description {
    ul {
      color: $color-darker-gray;
    }
  }
}

.content-block--split-width {
  .content-block-large {
    &__inner {
      padding: 30px 0;
    }
  }
}

.waitlist-form {
  &__submit {
    margin-top: 15px;
  }
  &__email-promotions-section {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.product-full {
  .sticky-add-to-bag__cta {
    .product-add-to-cart {
      .button {
        min-width: 275px;
      }
    }
  }
  .product-social {
    display: none;
  }
}

.mpp-container {
  &--custom {
    .product-brief__cta {
      .button {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $color-neutral-dark-gray;
        color: $color-neutral-dark-gray;
        line-height: 1.5;
        min-width: auto;
        padding: 0;
        text-align: $ldirection;
        @include transition(
          color $transition-duration,
          border-color $transition-duration
        );
        width: auto;
      }
    }
  }
}

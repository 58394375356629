.site-header-formatter {
  .cart-block {
    &__footer {
      clear: both;
    }
    &__content {
      padding: 0 1.7em;
    }
    .cart-confirm {
      &__engrave-container {
        display: none;
      }
    }
  }
}

.checkout-panel {
  &--viewcart {
    .go-shopping--btn {
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    .cart-items__item--remove {
      .remove_link--pc {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          position: relative;
        }
      }
      .remove_link.pc_hidden {
        display: block;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  &--offer-code {
    .button {
      padding: 10px 20px;
    }
  }
  &--gift-options {
    .button {
      padding: 10px 20px;
      margin-top: 25px;
    }
  }
  &--samples {
    .offer_heading {
      text-align: center;
    }
  }
  .shipping {
    .cpf-field {
      margin-bottom: 15px;
    }
  }
  .confirmation-content {
    &__actions {
      clear: both;
    }
    &__cancel-buttons {
      .button {
        padding: 20px;
      }
    }
  }
  &--email-and-sms-promotions {
    display: none;
  }
  &--confirmation {
    padding-bottom: 0;
    .confirmation-content {
      &__print-buttons {
        width: 100%;
        margin-bottom: 40px;
        float: none;
        .button {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 45%;
          }
        }
      }
    }
  }
  .sign-in-btn,
  .guest-btn {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    .account-signin {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.sticky-checkout-button {
  .continue-checkout {
    padding: 10px 20px;
  }
}

.checkout__sidebar {
  .viewcart-buttons-panel {
    border-bottom: 1px solid $color-light-gray;
    margin: 0 1em;
    padding: 1em 0;
    .continue-buttons {
      .btn {
        border-radius: 10px;
        display: block;
        font-size: 12px;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
        margin-top: 7px;
        position: relative;
        width: 85%;
        @include breakpoint($medium-up) {
          width: 325px;
        }
      }
      .btn-primary {
        background-color: $color-dark-gray;
        color: $color-white;
        height: 40px;
        line-height: 3.5;
        text-transform: uppercase;
        text-decoration: none;
        @include breakpoint($medium-up) {
          font-weight: bold;
        }
      }
      .gwp-paypal-checkout,
      .paypal-checkout {
        background-color: $color-white;
        @include breakpoint($medium-up) {
          background-image: url('/media/images/logos/paypal.gif');
          background-size: 80px;
          background-repeat: no-repeat;
        }
      }
      .gwp-paypal-checkout {
        @include breakpoint($medium-up) {
          border: 1px solid $color-dark-gray;
          background-position: 180px;
          height: 40px;
          font-weight: bold;
          line-height: 3.5;
          text-decoration: none;
          text-align: #{$ldirection};
          text-indent: 60px;
        }
      }
      .paypal-checkout {
        @include breakpoint($medium-up) {
          background-position: 200px;
        }
      }
    }
  }
}

.pre-order-message-display {
  clear: both;
}

.checkout--confirmation-page {
  .social-login-section {
    .social-login {
      display: inline;
      .facebook-logo {
        &-text {
          margin-#{$ldirection}: 25%;
        }
      }
    }
  }
}

.header-nav-section__section {
  .gnav-section-formatter {
    &__gnav-links-wrapper {
      > .gnav-links:not(.gnav-links--columns-break) {
        @include breakpoint($landscape-up) {
          flex: 0 25%;
        }
      }
    }
    &__content-wrapper {
      @include breakpoint($landscape-up) {
        overflow-x: hidden;
      }
    }
  }
}
